@keyframes showPopup {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.popup__form_popup {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0000004a;
    z-index: 9999;
    animation-name: showPopup;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    padding: 16px;
}
.popup__form_wrapper {
    position: relative;
    max-width: 600px;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--white);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
}
.popup__form_close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
    border: 0;
    background: transparent;
}
.popup__wrapper_info > h3{
    color: var(--dark);
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}
.popup__wrapper_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.popup__item_fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.popup__item_fields > label {
    width: 100%;
    background: var(--input-gray);
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 6px 16px;
    border-radius: 12px;
}
.popup__item_fields > label > input {
    width: 100%;
    height: 36px;
    outline: none;
    border: none;
    background: transparent;
    color: var(--black);
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}
.popup__item_actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.popup__item_actions > input[type="submit"] {
    width: 100%;
    padding: 16px 32px;
    background: var(--primary);
    border: none;
    outline: none;
    border-radius: 12px;
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    box-shadow: 0 2px 32px 0 var(--primary);
    transition: 0.4s;
    cursor: pointer;
}
.popup__item_actions > input[type="submit"]:hover {
    transform: scale(1.05);
}