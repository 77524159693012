.home__table_section {
  width: 100%;
  height: 100%;
  background: var(--black);
  padding-top: 60px;
  padding-bottom: 60px;
}
.home__table_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.home__table_title {
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.home__table_block {
  width: 100%;
}
.home__table_item {
  width: 100%;
  border-collapse: collapse;
}

.home__table_item th,
td {
  width: 16.6%;
  border: 1px solid var(--border-gray);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--white);
  text-align: center;
}

.home__table_item th {
  padding: 20px 32px;
}

.home__table_item td {
  padding: 20px 32px;
}

@media (max-width: 1000px) {
  .home__table_block {
    overflow: scroll;
  }

  .home__table_block::-webkit-scrollbar {
    display: none;
  }

  .home__table_item th,
  td {
    min-width: 172px;
  }
}

@media (max-width: 800px) {
  .home__table_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .home__table_title {
    font-size: 26px;
    line-height: 30px;
  }
  .home__table_item th,
  td {
    width: 140px;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 24px;
  }
}
