.cart__upload_choose {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.cart__choose_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.cart__choose_title {
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.cart__choose_blocks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.cart__blocks_item {
  width: 100%;
  height: 140px;
  background: var(--white);
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  border: 3.5px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}
.cart__blocks_item.active {
  border-color: var(--primary);
}
.cart__choose_fast {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cart__fast_title {
  color: var(--white);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.cart__fast_select {
  width: 100%;
  height: 54px;
  outline: none;
  border: none;
  padding: 0 20px;
  border-radius: 10px;
  background: var(--white);
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

@media (max-width: 800px) {
  .cart__choose_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .cart__choose_title,
  .cart__fast_title {
    font-size: 26px;
    line-height: 34px;
  }

  .cart__choose_blocks {
    flex-direction: column;
    gap: 15px;
  }

  .cart__blocks_item {
    height: 80px;
    border: 2px solid transparent;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
}
