.home__blog_item {
  width: 100%;
  height: 560px;
  background: var(--white);
  border-radius: 20px;
}
.home__blog_image {
  height: 40%;
  width: 100%;
  object-fit: cover;
  border-radius: 19px 19px 0 0;
}
.home__blog_info {
  height: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home__info_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.home__blog_name {
  color: var(--dark);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
}
.home__blog_description {
  color: var(--dark);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.home__info_action {
  padding-top: 10px;
  width: 100%;
}

.home__info_action > button {
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--primary) !important;
}

.home__blog_content {
  padding: 0 40px;
}

.home__blog_content > div {
  border-radius: 12px;
  cursor: grab;
}

.arrow__slide_left {
  position: absolute;
  top: 50%;
  z-index: 1001;
  transform: translate(0, -50%);
  left: -6px;
  cursor: pointer;
}

.arrow__slide_right {
  position: absolute;
  top: 50%;
  z-index: 1001;
  transform: translate(0, -50%);
  right: -6px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .arrow__slide_right {
    right: -6px;
  }

  .arrow__slide_left {
    left: -6px;
  }
}

@media (max-width: 600px) {
  .home__blog_content {
    padding: 0 20px;
  }

  .arrow__slide_right {
    right: -4px !important;
  }

  .arrow__slide_left {
    left: -4px !important;
  }

  .arrow__slide_right svg,
  .arrow__slide_left svg {
    width: 12px;
  }
  .home__blog_description {
    font-size: 16px;
  }
}
