.services__form_section {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.services__form_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.services__form_heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.services__heading_title {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.services__heading_subtitle {
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

@media (max-width: 800px) {
  .services__heading_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .services__heading_title {
    font-size: 26px;
    line-height: 34px;
  }

  .services__heading_subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
