.services__order_section {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.services__order_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.services__order_heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.services__heading_title {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.services__heading_subtitle {
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}
.services__order_main {
  width: 100%;
  display: flex;
  gap: 30px;
}
.services__order_main div h3,
.services__order_main div p {
  text-align: center;
}
.services__item_banner {
  width: 100%;
  display: flex;
  justify-content: center;
}
.services__item_icon {
  width: 60px;
  height: 60px;
}

@media (max-width: 1000px) {
  .services__order_main {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .services__heading_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .services__order_main {
    gap: 15px;
  }
  .services__heading_title {
    font-size: 26px;
    line-height: 34px;
  }
  .services__heading_subtitle {
    font-size: 16px;
    line-height: 20px;
  }
}
