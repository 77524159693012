.portfolio__banner_section {
  width: 100%;
  height: 100vh;
  background-image: url("../../../../../public/images/portfolio-banner.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.portfolio__banner_section > div {
  height: 100%;
}
.portfolio__banner_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.portfolio__banner_title {
  width: 78%;
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.portfolio__banner_text {
  width: 78%;
  color: var(--white);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

@media (max-width: 800px) {
  .portfolio__banner_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .portfolio__banner_title {
    font-size: 26px;
    line-height: 34px;
  }
  .portfolio__banner_text {
    font-size: 16px;
    line-height: 20px;
  }
}
