.services__advice_section {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.services__advice_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.servicse__advice_title {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.services__advice_main {
  width: 100%;
}

@media (max-width: 800px) {
  .servicse__advice_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .servicse__advice_title {
    font-size: 26px;
    line-height: 34px;
  }
}
