.home__portfolio_section {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background: var(--black);
}
.home__portfolio_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.home__portfolio_title {
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.home__portfolio_main {
  width: 100%;
  position: relative;
}
.home__portfolio_button {
  width: fit-content;
  padding: 16px 32px;
  background: var(--primary);
  border: none;
  outline: none;
  border-radius: 12px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  box-shadow: 0 2px 32px 0 var(--primary);
  transition: 0.4s;
}
.home__portfolio_button:hover {
  transform: scale(1.05);
}

@media (max-width: 800px) {
  .home__portfolio_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .home__portfolio_title {
    font-size: 26px;
    line-height: 30px;
  }
}
