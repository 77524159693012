.services__work_section {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.services__work_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.services__work_title {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.services__work_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.services__list_block {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
}
.services__list_circle {
  width: 90px;
  height: 98px;
  background-image: url("../../../../../public/images/list-circle.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services__circle_text {
  color: var(--white);
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  margin-top: -6px;
}

@media (max-width: 940px) {
  .services__circle_text {
    margin-top: -10px;
  }
}

@media (max-width: 800px) {
  .services__list_block {
    flex-direction: column;
    gap: 20px;
  }
  .services__circle_text {
    margin-top: 2px;
  }
  .services__work_list {
    gap: 40px;
  }
  .services__work_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .services__work_title {
    font-size: 26px;
    line-height: 34px;
  }
}
