.policy__section {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background: var(--black);
}
.policy__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.policy__wrapper_title {
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.policy__wrapper_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.policy__main_text {
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.policy__main_subtitle {
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.policy__main_control {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.policy__main_link {
  color: var(--primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .policy__wrapper_title {
    font-size: 26px;
    line-height: 34px;
  }
}
