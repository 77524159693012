.cart__upload_section {
  width: 100%;
  height: 100%;
  background: var(--black);
  padding-top: 60px;
  padding-bottom: 60px;
}
.cart__upload_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 60px;
}
.cart__actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}
.cart__button {
  width: fit-content;
  padding: 16px 32px;
  background: var(--primary);
  border: none;
  outline: none;
  border-radius: 12px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  box-shadow: 0 2px 32px 0 var(--primary);
  transition: 0.4s;
}

.cart__button:hover {
  transform: scale(1.05);
}

.cart__button.error {
  background: #924515;
  box-shadow: 0 2px 32px 0 #924515;
}

.cart__fields_error {
  color: #eb001b;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
}
