.blog__route_section {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}
.blog__route_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.main__route_active {
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.blog__details_banner {
  width: 100%;
  min-height: 100%;
}
.blog__banner_item {
  width: 100%;
  min-height: 100%;
}
.blog__details_section {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 100px;
}
.blog__details_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.blog__details_title {
  color: var(--black);
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
}
.blog__wrapper_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.blog__details_text:nth-child(even) {
  font-weight: bold;
}
.blog__details_subtitle {
  color: var(--black);
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 10px;
}
.blog__details_text {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 1280px) {
  .blog__banner_item {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

@media (max-width: 600px) {
  .blog__details_title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 0;
  }
}
