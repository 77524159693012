.home__process_section {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.home__process_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.home__process_heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.home__heading_title {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  user-select: none;
}
.home__heading_subtitle {
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  user-select: none;
}
.home__process_main {
  width: 100%;
  display: flex;
  gap: 30px;
}
.home__process_inner {
  width: calc(50% - 15px);
}
.home__process_banner {
  width: calc(50% - 15px);
}
.home__banner_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

@media (max-width: 1100px) {
  .home__process_main {
    flex-direction: column;
  }

  .home__process_inner,
  .home__process_banner {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .home__heading_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .home__heading_title {
    font-size: 26px;
    line-height: 34px;
  }

  .home__heading_subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .home__process_main {
    gap: 15px;
  }

  .home__banner_image {
    border-radius: 20px;
  }
}
