.services__main_section {
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  height: 100%;
}
.services__main_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.services__main_heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.services__heading_title {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.services__heading_subtitle {
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}
.services__main_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.services__block_item {
  width: 100%;
  display: flex;
  gap: 30px;
}
.services__item_inner {
  width: calc(50% - 15px);
}
.services__item_banner {
  width: calc(50% - 15px);
}
.services__banner_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.services__card_icon {
  width: 64px;
  height: auto;
}

@media (max-width: 1000px) {
  .services__block_item {
    flex-direction: column;
  }
  .services__item_inner,
  .services__item_banner {
    width: 100%;
  }
  .services__banner_special {
    order: 2;
  }
  .services__inner_special {
    order: 1;
  }
}

@media (max-width: 800px) {
  .services__heading_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .services__heading_title {
    font-size: 26px;
    line-height: 34px;
  }
  .services__heading_subtitle {
    font-size: 16px;
    line-height: 20px;
  }
  .services__block_item,
  .services__main_block {
    gap: 15px;
  }
  .services__banner_image {
    border-radius: 20px;
  }
}
