.blog__list_item {
  width: calc(33.33% - 12px);
  height: 580px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--card-gray);
  border-radius: 12px;
}
.blog__item_banner {
  width: 100%;
  height: 50%;
}
.blog__banner_item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
}
.blog__item_info {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
}
.blog__info_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.blog__info_title {
  color: var(--black);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.blog__info_description {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.blog__item_info > button {
  margin-top: 10px;
}

@media (max-width: 1000px) {
  .blog__list_item {
    width: calc(50% - 9px);
  }
  .blog__list_item {
    height: 740px;
  }
}

@media (max-width: 500px) {
  .blog__list_item {
    width: 100%;
  }
  .blog__list_item {
    height: 600px;
  }
}
