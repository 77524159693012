.home__main_section {
  width: 100%;
  height: 100%;
  background-image: url("../../../../../public/images/main-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.home__main_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home__wrapper_info {
  width: 46%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.home__info_title {
  color: var(--white);
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}
.home__info_description {
  color: var(--white);
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}
.home__info_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.home__inner_text {
  color: var(--white-gray);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.home__inner_blocks {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}
.home__blocks_item {
  height: 76px;
  padding: 6px;
  background: var(--white);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home__blocks_wrapper {
  width: 100%;
  height: 100%;
  background-image: url("../../../../../public/images/home-line.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 0 22px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
.home__item_text {
  color: var(--dark);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}
.home__item_title {
  color: var(--dark);
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
}
.home__wrapper_form {
  width: 40%;
}

@media (max-width: 1060px) {
  .home__wrapper_info {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  .home__main_wrapper {
    flex-direction: column;
    gap: 40px;
  }
  .home__wrapper_info,
  .home__wrapper_form {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .home__info_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .home__main_section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .home__blocks_wrapper {
    padding: 0 14px;
  }
  .home__info_title {
    font-size: 26px;
    line-height: 34px;
  }
  .home__wrapper_info {
    gap: 16px;
  }
  .home__info_description {
    font-size: 16px;
    line-height: 20px;
  }
  .home__inner_text {
    font-size: 14px;
    line-height: 18px;
  }
  .home__info_inner {
    gap: 12px;
  }
}

@media (max-width: 520px) {
  .home__inner_blocks {
    gap: 12px;
    flex-direction: column;
    align-items: flex-start;
  }
  .home__item_text {
    font-size: 14px;
    line-height: 16px;
  }
  .home__item_title {
    font-size: 22px;
    line-height: 24px;
  }
}
