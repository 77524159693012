.portfolio__works_section {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.portfolio__works_wrapper {
  width: 100%;
}
.portfolio__works_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.portfolio__works_empty {
  padding-top: 60px;
  padding-bottom: 60px;
  color: var(--black);
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}
.portfolio_work_item {
  background: var(--white);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 20px;
}
.portfolio__item_info {
  height: 240px;
  overflow: scroll;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.portfolio__item_category {
  color: var(--gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.portfolio__item_name {
  color: var(--dark);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
}
.portfolio__item_description {
  height: 145px;
  overflow: scroll;
  color: var(--dark);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

@media (max-width: 600px) {
  .portfolio__works_list {
    gap: 15px;
  }
}
