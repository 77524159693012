.portfolio__work_item {
  width: 100%;
  height: 100%;
  background: var(--card-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  border-radius: 12px;
  gap: 14px;
}
.portfolio__work_block {
  width: calc(50% - 7px);
  padding: 20px;
  border-radius: 12px;
  height: 100%;
  background: #d2d2d2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.portfolio__work_text {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.portfolio__work_count {
  color: var(--primary);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.portfolio__play_icon {
  cursor: pointer;
}

@media (max-width: 768px) {
  .portfolio__work_item {
    flex-direction: column;
  }
  .portfolio__work_block {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .portfolio__work_block {
    padding: 12px 16px;
    border-radius: 8px;
  }

  .portfolio__work_item {
    padding: 8px;
  }
}
