.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
}

.loader__text {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  line-height: 20px;
}
