.banner__form_banner {
  width: 100%;
  height: 600px;
  background-image: url("../../../public/images/form-bg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;
  display: flex;
  align-items: center;
  padding: 50px;
  gap: 60px;
}
.banner__form_info {
  width: calc(55% - 30px);
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 40px;
}
.banner__info_title {
  color: var(--white);
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}
.banner__info_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.banner__info_text {
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.banner__info_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.banner__list_item {
  display: flex;
  align-items: center;
  gap: 12px;
}
.banner__list_item p {
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.banner__form_block {
  width: calc(45% - 30px);
}
.banner__form_block div::before {
  background-image: none;
}

@media (max-width: 1200px) {
  .banner__form_banner {
    background-image: none;
    height: 100%;
    background: var(--primary);
    border-radius: 30px;
    padding: 30px;
  }
  .banner__form_info {
    padding-top: 0;
  }
}

@media (max-width: 1000px) {
  .banner__form_banner {
    flex-direction: column;
  }
  .banner__form_info,
  .banner__form_block {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .banner__form_banner {
    width: calc(100% + 30px);
  }

  .banner__form_info {
    gap: 20px;
  }

  .banner__info_title {
    font-size: 22px;
    line-height: 26px;
  }

  .banner__info_inner,
  .banner__info_list {
    gap: 12px;
  }
}

@media (max-width: 400px) {
  .banner__form_banner {
    padding: 30px 18px;
    border-radius: 20px;
  }
}
