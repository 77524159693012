.blog__section_error {
  width: 100%;
  height: 80vh;
}
.blog__section_error > div {
  height: 100%;
}
.blog__error_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 28px;
}
.blog__error_text {
  color: var(--black);
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}

@media (max-width: 800px) {
  .blog__error_text {
    font-size: 24px;
    line-height: 26px;
  }
}

@media (max-width: 600px) {
  .blog__error_text {
    font-size: 20px;
    line-height: 24px;
  }
}
