.cart__upload_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.cart__area_title {
  width: 80%;
  color: var(--white);
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
}
.cart__area_main {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 30px;
}
.cart__area_block,
.cart__area_add {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.cart__area_block {
  gap: 10px;
}
.cart__block_control {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.cart__control_filename {
  color: var(--input-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.cart__area_textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 12px;
  background: var(--input-gray);
  color: var(--black);
  font-size: 14px;
  resize: none;
  font-weight: 400;
  line-height: 16px;
  padding: 15px;
}
.cart__area_add {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px dashed var(--white);
  cursor: pointer;
  transition: 0.4s;
  padding: 20px;
}
.cart__area_add:hover {
  background: var(--white);
}
.cart__area_add:hover .cart__add_icon path {
  fill: var(--black);
}
.cart__area_add:hover .cart__add_text {
  color: var(--black);
}
.cart__add_icon {
  width: 20px;
}
.cart__add_text {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  display: flex;
}
.cart__block_control > div {
  padding-left: 15px;
  padding-right: 15px;
}
.form__fields_control {
  width: 100%;
  background: var(--input-gray);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 6px 16px;
  border-radius: 12px;
}
.form__control_input {
  width: 100%;
  height: 42px;
  outline: none;
  border: none;
  background: transparent;
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}
.form__control_input::placeholder {
  color: var(--line-gray);
}
.form__control_link {
  position: relative;
}
.form__button:hover {
  transform: scale(1.05);
}
.form__link_info {
  width: 100%;
  position: absolute;
  width: 26px !important;
  height: 26px !important;
  border-radius: 50%;
  right: -12px;
  top: -12px;
}
.cart__area_heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cart__area_text {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.form__info_circle {
  position: absolute;
  width: 26px;
  height: 26px;
  background: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  cursor: pointer;
}
.form__info_circle img {
  width: auto;
  height: 10px;
}
.form__info_block {
  width: 215px;
  position: absolute;
  z-index: 2;
  background: var(--primary);
  border-radius: 6px;
  padding: 10px;
  right: 0;
  top: -70px;
  display: none;
}
.form__link_info:hover .form__info_block {
  transition: 0.4s all;
  display: block;
}
.form__info_text {
  color: var(--white);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.cart__block_delete {
  width: 28px;
  height: 28px;
  background: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: -12px;
}

.cart__block_delete > img {
  width: 12px;
  height: 14px;
}

@media (max-width: 900px) {
  .cart__area_main {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  .cart__area_title {
    width: 100%;
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 600px) {
  .cart__area_title {
    font-size: 20px;
    line-height: 24px;
  }

  .cart__area_main {
    grid-template-columns: repeat(1, 1fr);
  }

  .cart__area_add {
    height: 120px;
  }
}

@media (max-width: 500px) {
}
