.card__block {
  width: 100%;
  min-height: 100%;
  border-radius: 30px;
  background: var(--card-gray);
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card__block div {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.card__block h3 {
  color: var(--dark);
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}

.card__block p {
  color: var(--dark);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.card__block ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 14px;
}

.card__block ul li {
  list-style-type: disc;
  color: var(--dark);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.card__block ul li::marker {
  color: var(--primary);
}

@media (max-width: 600px) {
  .card__block {
    border-radius: 20px;
    padding: 24px;
    gap: 10px;
  }

  .card__block h3 {
    font-size: 20px;
  }

  .card__block p {
    line-height: 22px;
  }
}
