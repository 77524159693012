.blog__main_section {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.blog__main_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.blog__main_title {
  color: var(--black);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.blog__main_block {
  width: 100%;
}
.blog__block_list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 60px;
}

@media (max-width: 800px) {
  .blog__main_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .blog__main_title {
    font-size: 26px;
    line-height: 34px;
  }

  .blog__main_wrapper {
    gap: 28px;
  }

  .blog__block_list {
    row-gap: 40px;
  }
}
