:root {
  --primary: #ee701f;
  --white: #ffffff;
  --black: #1d1d1b;
  --dark-gray: #292929;
  --border-light-gray: #93939380;
  --border-gray: #939393;
  --white-gray: #ffffffb3;
  --gray: #666b7a;
  --card-gray: #f2f2f2;
  --light-dark: #2e2e2c;
  --size-dark: #20201f;
  --line-gray: #515150;
  --input-gray: #f0f2f5;
}
