.home__about_section {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.home__about_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.home__about_title {
  color: var(--dark);
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}
.home__about_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.home__main_banner {
  width: 100%;
  display: flex;
  gap: 30px;
}
.home__banner_item {
  width: calc(50% - 15px);
}
.home__banner_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.home__banner_card {
  width: calc(50% - 15px);
}

@media (max-width: 800px) {
  .home__main_banner {
    flex-direction: column;
  }
  .home__banner_item,
  .home__banner_card {
    width: 100%;
  }
  .home__about_title {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 600px) {
  .home__about_main,
  .home__main_banner {
    gap: 15px;
  }
  .home__about_title {
    font-size: 26px;
    line-height: 34px;
  }
  .home__banner_image {
    border-radius: 20px;
  }
}
