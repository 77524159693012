.admin__content_sidebar {
  width: 20%;
  background: var(--dark-gray);
  border-radius: 12px 0 0 12px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.admin__sidebar_wrapper {
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.admin__wrapper_pages {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.admin__wrapper_item {
  width: 100%;
  background: transparent;
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--white);
  text-align: center;
  transition: 0.4s;
  cursor: pointer;
}
.admin__wrapper_item:hover {
  background: var(--primary);
  color: var(--dark);
}
.admin__wrapper_item.active {
  background: var(--primary);
  color: var(--dark);
}
.admin__wrapper_logout {
  width: 100%;
}

@media (max-width: 1000px) {
  .admin__content_sidebar {
    width: 100%;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-right: none;
  }
}
