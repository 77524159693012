.admin__update_section {
  width: 100%;
  height: 100%;
  padding: 20px 0 80px 0;
  background: var(--black);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.admin__update_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.admin__wrapper_route {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.admin__router_icon:hover {
  cursor: pointer;
}

.admin__router_icon:hover path {
  stroke: var(--primary);
}

.admin__router_name {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: var(--white);
  margin-top: 2px;
  cursor: pointer;
}

.admin__router_name:hover {
  color: var(--primary);
}

.admin__router_active {
  color: var(--primary);
}

.admin__wrapper_title {
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: var(--white);
}

.admin__wrapper_main {
  width: 100%;
  background: var(--dark-gray);
  border-radius: 12px;
  padding: 40px 20px;
}

.admin__form_block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}

.admin__block_control {
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.admin__control_label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--white);
}
.admin__control_image {
  width: 60px;
  height: 60px;
}
.admin__control_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin__control_upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.admin__control_add {
  width: fit-content;
  background: var(--primary);
  border-radius: 12px;
  border: 1px solid var(--primary);
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 500;
  color: var(--dark);
  transition: 0.4s;
  box-shadow: 0 24px 46px rgba(240, 92, 0, 0.332);
  user-select: none;
}
.admin__control_add:hover {
  background: transparent;
  color: var(--primary);
}
.admin__control_item {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.admin__control_field {
  width: 100%;
  border: 1px solid var(--white);
  border-radius: 12px;
  padding: 16px 26px 14px 26px;
  background: transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
}

.admin__control_field::placeholder {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
}

.admin__block_actions {
  width: 100%;
  display: flex;
  gap: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.admin__actions_button {
  width: calc(50% - 15px);
  background: var(--primary);
  border-radius: 12px;
  border: 2px solid var(--primary);
  outline: none;
  cursor: pointer;
  padding: 16px 48px;
  font-size: 20px;
  font-weight: 500;
  color: var(--dark);
  transition: 0.4s;
  box-shadow: 0 24px 46px rgba(240, 92, 0, 0.332);
  user-select: none;
  text-transform: uppercase;
}
.admin__add_button {
  margin-top: 20px;
  width: 100%;
  background: var(--primary);
  border-radius: 12px;
  border: 2px solid var(--primary);
  outline: none;
  cursor: pointer;
  padding: 16px 48px;
  font-size: 20px;
  font-weight: 500;
  color: var(--dark);
  transition: 0.4s;
  box-shadow: 0 24px 46px rgba(240, 92, 0, 0.332);
  user-select: none;
  text-transform: uppercase;
}
.admin__actions_button:hover {
  background: transparent;
  color: var(--primary);
}

.admin__button_full {
  width: 100% !important;
}

.admin__button_slider {
  width: 100% !important;
}
.admin__drag_preview {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.admin__drag_slider {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.admin__drag_image {
  width: 100%;
  height: 100%;
}
.error_message {
  font-size: 13px;
  color: #eb001b;
  font-weight: 300;
  line-height: 16px;
}

.prevFlex {
  display: flex;
  gap: 10px;
}

@media (max-width: 1200px) {
  .admin__update_section {
    padding: 20px 15px 80px 15px;
  }
}

@media (max-width: 768px) {
  .admin__form_block {
    flex-direction: column;
  }

  .admin__block_control {
    width: 100%;
  }

  .admin__block_actions {
    gap: 20px;
  }

  .admin__actions_button {
    width: calc(50% - 10px);
  }

  .admin__update_section {
    padding: 20px 15px 40px 15px;
  }
}

@media (max-width: 600px) {
  .admin__block_actions {
    flex-direction: column;
  }

  .admin__wrapper_route {
    display: none;
  }

  .admin__update_section {
    background-image: none;
  }

  .admin__actions_button {
    width: 100%;
    padding: 12px 48px;
    font-size: 16px;
    border-width: 1px;
  }

  .admin__wrapper_title {
    font-size: 22px;
    line-height: 26px;
  }

  .admin__update_wrapper {
    gap: 30px;
  }
}
