.form__block {
  width: 100%;
  background: var(--white);
  border-radius: 20px;
  position: relative;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}
.form__block::before {
  content: "";
  width: 96px;
  height: 66px;
  position: absolute;
  background-image: url("../../../public/images/form-line.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: -120px;
  bottom: 20%;
}
.form__block_wrapper {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form__wrapper_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form__info_title {
  color: var(--dark);
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.form__wrapper_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form__item_fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form__fields_inner {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}
.form__fields_control {
  width: 100%;
  background: var(--input-gray);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 6px 16px;
  border-radius: 12px;
}
.form__fields_control > svg {
  width: 12px;
}
.form__fields_control > div {
  width: 100%;
  height: 36px;
}
.form__fields_control > div > div {
  border: none !important;
  box-shadow: none !important;
}
.form__fields_control > div > div > div {
  padding: 0;
}
.form__fields_control > div > div > div > div {
  color: var(--line-gray);
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 !important;
}
.form__control_icon {
  width: 12px;
}
.form__control_input {
  width: 100%;
  height: 36px;
  outline: none;
  border: none;
  background: transparent;
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}
.form__checkbox_control {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.form__checkbox_control input {
  accent-color: var(--primary);
}
.form__checkbox_control label {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.form__control_file {
  width: 100%;
  height: 36px;
  outline: none;
  border: 1px solid transparent;
  background: transparent;
  color: var(--line-gray);
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form__control_input::placeholder {
  color: var(--line-gray);
}
.form__promocode_error {
  color: #eb001b;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.form__item_actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form__actions_checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}
.form__checkbox_input {
  accent-color: var(--primary);
}
.form__checkbox_text {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.form__checkbox_primary {
  color: var(--primary);
}
.form__item_actions button {
  width: 100%;
}
.form__inner_upload {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.form__file_name {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.form__button {
  width: fit-content;
  padding: 16px 32px;
  background: var(--primary);
  border: none;
  outline: none;
  border-radius: 12px;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  box-shadow: 0 2px 32px 0 var(--primary);
  transition: 0.4s;
}
.form__button:hover {
  transform: scale(1.05);
}

@media (max-width: 1060px) {
  .form__block::before {
    background-image: none;
  }
}

@media (max-width: 600px) {
  .form__fields_inner {
    flex-direction: column;
    gap: 10px;
  }
  .form__block_wrapper {
    padding: 24px 18px;
  }
  .form__item_fields {
    gap: 10px;
  }
}
