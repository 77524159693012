*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

ul,
ol,
li {
  list-style: none;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1280px) {
  section {
    padding: 0 15px;
  }
}
