.blog__more_section {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
}
.blog__more_wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 60px;
}
